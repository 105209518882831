import { Injectable } from '@angular/core';
import { CoreService } from '../../../core/service/core.service';
import { NotificationsModalComponent } from '../modal/notifications.modal';
import { SubscriptionService } from '../../../shared/utilities/subscription';

@Injectable()
export class NotificationsService {

  public coreService: CoreService;

  private subscriptionService = new SubscriptionService();

  public toast(message: string, style?: string) {
    return new Promise(resolve => this.show('toast', '', message, style).then(() => resolve()));
  }

  public alert(title: string, message: string) {
    return new Promise(resolve => this.show('alert', title, message).then(() => resolve()));
  }

  public confirm(title: string, message: string) {
    return new Promise(resolve => this.show('confirm', title, message).then(confirmed => resolve(confirmed)));
  }

  public password(message: string) {
    return new Promise(resolve => this.show('password', '', message).then(password => resolve(password)));
  }

  public error(title: string, message: string) {
    return new Promise(resolve => this.show('error', title, message).then(() => resolve()));
  }

  public show(type: string, title: string, message: string, style = '') {
    return new Promise(resolve => {
      this.subscriptionService.add('notifications-modal', this.coreService.getModal('notifications-modal').filter(_ => !!_).subscribe((modal: NotificationsModalComponent) => {
        this.subscriptionService.remove('notifications-modal');
        switch (type) {
          case 'error':
            modal.cancel.take(1).subscribe(() => {
              modal.dismiss().then(() => resolve());
            });
            modal.showError(title, message).then(() => {});
            break;
          case 'alert':
            modal.cancel.take(1).subscribe(() => {
              modal.dismiss().then(() => resolve());
            });
            modal.showAlert(title, message).then(() => {});
            break;
          case 'password':
            let isEntered = false;
            modal.continue.take(1).subscribe(password => {
              isEntered = true;
              modal.dismiss().then(() => resolve(password));
            });
            modal.cancel.take(1).subscribe(() => {
              if (!isEntered) {
                modal.dismiss().then(() => resolve(false));
              }
            });
            modal.showPassword(message).then(() => {});
            break;
          case 'confirm':
            let isContinue = false;
            modal.continue.take(1).subscribe(() => {
              isContinue = true;
              modal.dismiss().then(() => resolve(true));
            });
            modal.cancel.take(1).subscribe(() => {
              if (!isContinue) {
                modal.dismiss().then(() => resolve(false));
              }
            });
            modal.showConfirm(title, message).then(() => {});
            break;
          case 'toast':
            modal.showToast(message, style).then(() => {
              window.setTimeout(() => modal.dismiss().then(() => resolve()), 2000);
            });
            break;
        }
      }));
      this.coreService.showModal('notifications-modal');
    });
  }

}
