import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { GPT, GPTIngested, GPTMessage, GPTResponse } from './gpt.interface';
import { CoreService } from '../../core/service/core.service';
import { CoreUtilities } from '../../core/utilities/core.utilities';
import { GptPrivateGPTService } from './gpt.privategpt.service';
import { CoreFilter, TreeNode } from '../../core/interface/core.interface';
import { GPTModalComponent } from './modal/gpt.modal';
import { NODES_TYPE_AGENT, NODES_TYPE_DATASOURCE, NODES_TYPE_FIELDS, NODES_TYPE_MODEL, NODES_TYPE_NODETYPEGROUP, NODES_TYPE_ONLY_ONE_STACK, NODES_TYPE_SKILLS, NODES_TYPE_WORKFLOW } from '../../shared/api/nodes/nodes.models';
import { FormService } from '../../components/form/service/form.service';
import { GptAutogenService } from './gpt.autogen.service';
import { take } from 'rxjs/operators';
import { FormModalComponent } from '../../components/form/modal/form.modal';
import { FormInterface, FormResult } from '../../components/form/interface/form.interface';
import { SubscriptionService } from '../../shared/utilities/subscription';
import { CoreTransformer } from '../../core/transformer/core.transformer';
import { Model } from '../../shared';

@Injectable()
export class GPTService {

  /* Core Service */
  public coreService: CoreService;

  /* The GPTs */
  private gpt = {};

  /* Private GPT service */
  private privateGPTService: GptPrivateGPTService;

  /* Autogen service */
  private autogenService: GptAutogenService;

  /* Subscription service */
  private subscriptionService = new SubscriptionService();

  public constructor(private coreUtilities: CoreUtilities, private translateService: TranslateService, private formService: FormService, private coreTransformer: CoreTransformer) {}

  public setCoreService(coreService: CoreService) {
    this.coreService = coreService;
    this.privateGPTService = new GptPrivateGPTService(coreService, this.coreUtilities, this.translateService);
    this.autogenService = new GptAutogenService(coreService, this.coreUtilities, this.translateService);
    this.formService.coreService = coreService;
  }

  /**
   * Open the modal
   *
   * @param {TreeNode[]} selectedTreeNodes
   * @param {TreeNode} configurationNode
   */
  public openModal(selectedTreeNodes: TreeNode[], configurationNode: TreeNode) {
    /* Load modal */
    this.coreService.modal('gpt-modal', (modal: GPTModalComponent) => {
      /* Set the values */
      modal.treeNodes = selectedTreeNodes;
      /* Set the configuration node */
      modal.configurationNode = configurationNode;
      /* Open modal */
      modal.show();
    });
  }

  /**
   * Register a new gpt
   *
   * @param {string} uuid
   * @param {string} type
   * @param {string} url
   * @param ip
   * @param username
   * @param pem
   * @param autogen
   * @param user_id
   * @returns {GPT}
   */
  public register(uuid: string, type: string, url: string, ip?: string, username?: string, pem?: string, autogen?: string[], user_id?: string): GPT {

    /* Store the gpt */
    this.gpt[uuid] = { uuid, type, url, ip, username, pem, autogen, user_id } as GPT;

    /* Connect and return event emitter */
    return this.connect(uuid);
  }

  /**
   * Connect to GPT
   *
   * @param {string} uuid
   * @returns {GPT}
   * @private
   */
  private connect(uuid: string): GPT {

    /* Get the gpt */
    const gpt = this.gpt[uuid] as GPT;

    /* The event emitter */
    gpt.events = new BehaviorSubject<GPTResponse>(undefined);

    /* The uuid */
    gpt.uuid = uuid;

    /* Do specific stuff */
    switch (gpt.type) {
      case 'privategpt':
        this.privateGPTService.connect(gpt);
        break;
      case 'autogen':
        this.autogenService.connect(gpt);
        break;

    }

    /* Return the emitter */
    return gpt;
  }

  /**
   * Prompt
   *
   * @param gptId
   * @param promptId
   * @param prompt
   * @param conversations
   * @param systemPrompt
   * @param mode
   * @param userDocumentIds
   * @param externalSystems
   * @param autogenWorkflow
   */
  public prompt(gptId: string, promptId: string, prompt: string, conversations: GPTMessage[], systemPrompt?: string, mode?: string, userDocumentIds?: string[], externalSystems?: TreeNode[], autogenWorkflow?: TreeNode) {
    /* Get the gpt */
    const gpt = this.gpt[gptId] as GPT;

    /* Do specific stuff */
    switch (gpt.type) {
      case 'privategpt':
        this.privateGPTService.sendPrompt(gpt, promptId, prompt, conversations, systemPrompt, mode, userDocumentIds, externalSystems);
        break;
      case 'autogen':
        this.autogenService.sendPrompt(gpt, promptId, prompt, autogenWorkflow, conversations, systemPrompt);
        break;
    }
  }

  /**
   * Close the connection
   *
   */
  public closeConnection() {
    /* CLose private GPT */
    if (this.privateGPTService !== undefined) {
      this.privateGPTService.closeConnection();
    }
  }

  /**
   * Generate system prompt out of nodes
   *
   * @param gptMessage
   * @param {TreeNode[]} treeNodes
   * @param {TreeNode} configurationNode
   * @returns {string}
   */
  public setGPTMessage(gptMessage: GPTMessage, treeNodes: TreeNode[], configurationNode: TreeNode): GPTMessage {
    /* Fields node */
    let fieldsNode: TreeNode;

    const children = configurationNode.children;
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      switch (child.nodeType) {
        case NODES_TYPE_FIELDS:
          fieldsNode = child;
          break;
        case NODES_TYPE_DATASOURCE:
          /* Get the model ids */
          const modelIds = this.coreUtilities.unique(treeNodes.map(treeNode => treeNode.modelId));
          /* Set the filters */
          const filters = [{ by: 'nodeType', value: child.children.map(c => c.nodeType) }, { by: 'modelId', value: modelIds }] as CoreFilter[];
          /* Get nodes by filters */
          treeNodes = treeNodes.concat(this.coreService.getInstantNodes({ filters, ignoreCache: true, ignoreGlobalFilter: true }));
          break;
      }
    }

    if (fieldsNode !== undefined) {
      const fields = [];
      const fieldChildren = fieldsNode.children.sort((a, b) => a.positionX - b.positionX);
      const fieldsCount = fieldChildren.length;
      /* Iterate over the tree nodes */
      for (let i = 0; i < fieldsCount; i++) {
        const fieldChild = fieldChildren[i];
        /* Set field */
        fields.push(fieldChild.formFieldId);
      }
      gptMessage.nodeFields = fields;
      if (treeNodes !== undefined) {
        gptMessage.nodeIds = treeNodes.map(treeNode => treeNode.id);
      }
    }

    return gptMessage;
  }

  /**
   * Upload files
   *
   * @param {string} gptId
   * @param {FileList} files
   */
  public ingestFile(gptId: string, files: FileList) {
    /* Get the gpt */
    const gpt = this.gpt[gptId] as GPT;

    /* Loading event emitter */
    const eventEmitter = new EventEmitter<boolean>();

    /* Do specific stuff */
    switch (gpt.type) {
      case 'privategpt':
        this.privateGPTService.ingestFile(gpt, files, response => {
          eventEmitter.emit(response);
        });
        break;
    }

    /* Return event emitter */
    return eventEmitter;
  }

  /**
   * Delete ingested file
   *
   * @param {string} gptId
   * @param {GPTIngested} ingestFile
   * @returns {EventEmitter<boolean>}
   */
  public ingestDelete(gptId: string, ingestFile: GPTIngested) {
    /* Get the gpt */
    const gpt = this.gpt[gptId] as GPT;

    /* Loading event emitter */
    const eventEmitter = new EventEmitter<boolean>();

    /* Do specific stuff */
    switch (gpt.type) {
      case 'privategpt':
        this.privateGPTService.ingestDelete(gpt, ingestFile, () => {
          /* Delete tree node */
          this.getIngestDocuments(gpt, response => eventEmitter.emit(response));
        });
        break;
    }

    /* Return event emitter */
    return eventEmitter;
  }

  /**
   * Get ingest documents
   *
   * @param gpt
   * @param callback
   */
  public getIngestDocuments(gpt: GPT, callback: Function) {
    /* Do specific stuff */
    switch (gpt.type) {
      case 'privategpt':
        this.privateGPTService.getIngestDocuments(gpt, response => {
          callback(response);
        });
        break;
    }
  }

  /**
   * Create something via POST Request
   *
   * @param {GPT} gpt
   * @param treeNode
   * @param {Function} callback
   * @param del
   */
  public createByTreeNode(gpt: GPT, treeNode: TreeNode, callback: Function, del = false) {
    /* Do specific stuff */
    switch (gpt.type) {
      case 'autogen':
        switch (treeNode.nodeType) {
          case NODES_TYPE_SKILLS:
            this.autogenService.createSkill(gpt, treeNode, response => {
              callback(response);
            }, del);
            break;
          case NODES_TYPE_MODEL:
            this.autogenService.createModel(gpt, treeNode, response => {
              callback(response);
            }, del);
            break;
          case NODES_TYPE_AGENT:
            this.autogenService.createAgent(gpt, treeNode, response => {
              callback(response);
            }, del);
            break;
          case NODES_TYPE_WORKFLOW:
            this.autogenService.createWorkflow(gpt, treeNode, response => {
              callback(response);
            }, del);
            break;
        }
        break;
    }
  }

  public getNewSession(gptId: string, model: Model, callback: Function) {

    /* Get the gpt */
    const gpt = this.gpt[gptId] as GPT;

    /* Get the session */
    switch (gpt.type) {
      case 'autogen':
        this.coreService.getModal('form').take(1).subscribe((modal: FormModalComponent) => {

          /* Get the model node type */
          const modelNode = { nodeType: this.coreTransformer.modelTypeToNodeType(model.type), children: [], unfilteredChildren: [] } as TreeNode;

          /* Set the configuration nodes */
          const configuration = [
            { nodeType: NODES_TYPE_NODETYPEGROUP, children: [ { nodeType: NODES_TYPE_WORKFLOW, children: [] } ] },
            { nodeType: NODES_TYPE_MODEL, children: [modelNode], unfilteredChildren: [modelNode] },
            { nodeType: NODES_TYPE_ONLY_ONE_STACK, children: [], unfilteredChildren: [] }
          ];

          /* Set form */
          const form: FormInterface = { tabs: [{ entry: { key: 'header-1', label: '' }, children: this.formService.buildFormEntries({ children: [
                  { name: 'Select workflow', formFieldControlType: 'node-select', unfilteredChildren: configuration, children: configuration } as TreeNode
          ]} as TreeNode)}]};

          /* Register the listeners */
          this.subscriptionService.add('update', modal.update.subscribe((result: FormResult) => {
            const selectedWorkflow = result.delta.first().selected[0];
            if (selectedWorkflow === undefined) {
              this.coreService.toast('Please select a workflow');
            } else {
              modal.isLoading = true;
              this.autogenService.getNewSession(gpt, selectedWorkflow, session => {
                modal.dismiss();
                callback({ 'gpt-session-id': session.id, 'gpt-autogen-workflow': selectedWorkflow.id });
              });
            }
          }));
          /* Show modal */
          modal.show(form, [{ children: [], unfilteredChildren: [], modelId: model.id } as TreeNode]);
        });
        break;
      default:
        callback();
    }
  }

}
