import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as selectors from './tree.selectors';
import { TreeAction } from './tree.actions';
import { SharedService } from '../shared/shared.service';
import { AppState } from '../../app.reducer';
import { getAll } from './tree.selectors';

@Injectable()
export class TreeService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: TreeAction) {
    super(store, action, selectors);
  }

  public loadByBusinessArea(businessAreaId: number): void {
    this.store.dispatch(this.action.load(businessAreaId));
  }

  public getAllByBusinessArea(businessAreaId: number) {
    return this.getAPIState().let(this.selectors.getAll());
  }

}
