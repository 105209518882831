import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

import { ValueMinerAPIUrl, ValueMinerExportAPIUrl, ValueMinerMessagingAPIUrl, ValueMinerOAuthUrl, ValueMinerGoUrl } from '../tokens';
import { TokenService } from './token.service';
import { AppGlobal } from '../../../../app.global';

@Injectable()
export class BackendService {

  protected http: HttpClient;
  protected token: TokenService;
  protected legacyUrl: string;

  public static pathJoin(parts: string[]) {
    return parts.join('/').replace(/([^:]\/)\/+/g, '$1');
  }

  public get apiUrl() {
    return this.legacyUrl;
  }

  constructor(@Inject(ValueMinerAPIUrl) apiLegacyUrl: string,
              @Inject(ValueMinerMessagingAPIUrl) messagingApiUrl: string,
              @Inject(ValueMinerExportAPIUrl) exportApiUrl: string,
              @Inject(ValueMinerOAuthUrl) oauthApiUrl: string,
              @Inject(ValueMinerGoUrl) goUrl: string,
              http: HttpClient,
              token: TokenService) {
    this.legacyUrl = apiLegacyUrl;
    this.http = http;
    this.token = token;
  }

  public get(path: string, token?: string): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('GET', url, {}, true, token);
  }

  public post(path: string, body: any, stringify = true, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json', token?: string): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('POST', url, body, stringify, token, responseType);
  }

  public postToDomain(domain: string, path: string, body: any, stringify = true, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json', token?: string): Observable<any> {
    const url = BackendService.pathJoin([domain, path]);
    return this.request('POST', url, body, stringify, token, responseType);
  }

  public raw(method: string, url: string, body: any, stringify = true, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json', token?: string, options?: any): Observable<any> {
    return this.request(method, url, body, stringify, token, responseType, options);
  }

  public put(path: string, body: any, token ?: string): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('PUT', url, body, true, token);
  }

  public remove(path: any, body?: any): Observable<any> {
    const url = BackendService.pathJoin([this.apiUrl, path]);
    return this.request('DELETE', url, body);
  }

  public request(method: string, url: string, body: {} = {}, stringify = true, token?: string, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json', options = {}): Observable<{}> {
    return this.sendRequest((!!token ? null : this.token.get()), method, url, body, stringify, token, responseType, options)
      .catch((error: any) => {
        if (error.status === 401) {
          return this.sendRequest(this.token.refresh(), method, url, body, stringify, undefined, responseType, options);
        }
        return Observable.throw(error);
      }).catch((err: any) => {
        if (typeof err.json === 'function') {
          err = err.json();
        }
        return Observable.throw(err);
      });
  }

  protected sendRequest(tokenObs: Observable<string>, method: string, url: string, body: {} = {}, stringify = true, token?: string, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json', options = {}): Observable<Object> {
    if (!isNullOrUndefined(token)) {
      if (token === '') {
        return this.doRequest(method, url, true, token, stringify, body, responseType, options);
      }
      return new BehaviorSubject(token).flatMap((accessToken: string) => {
        return this.doRequest(method, url, true, accessToken, stringify, body, responseType, options);
      });
    }
    return tokenObs.flatMap((accessToken: string) => {
      return this.doRequest(method, url, false, accessToken, stringify, body, responseType, options);
    });
  }

  protected doRequest(method: string, url: string, useToken: boolean, accessToken: string, stringify: boolean, body: any, responseType: 'json' | 'blob' | 'arraybuffer' | 'text' = 'json', options = {}) {
     /* Headers */
    let headers = { 'Accept': 'application/vnd.api.v2+json' };
    /* Set instance */
    if (AppGlobal.instanceId !== undefined) {
      headers['Instance'] = '' + AppGlobal.instanceId;
    }
    /* Set business area */
    if (AppGlobal.businessAreaId !== undefined) {
      headers['BA'] = '' + AppGlobal.businessAreaId;
    }
    /* Authorization */
    headers['Authorization'] = (useToken ? 'Token ' : 'Bearer ') + accessToken;
    /* Content type */
    if (stringify) {
      headers['Content-Type'] = 'application/json';
    }
    /* Pre filter */
    const preFilter = localStorage.getItem('valueminer.prefilter-entry-points');
    if (preFilter !== undefined && preFilter !== null && preFilter !== 'null') {
      headers['ENTRY-POINTS'] = preFilter;
    }

    /* Replace header */
    if (options !== undefined && options['headers'] !== undefined) {
      headers = Object.assign(headers, options['headers']);
    }
    /* Body */
    body = stringify ? JSON.stringify(body) : body;

    /* Send request */
    return this.http.request(method, url, Object.assign({ headers, body, responseType }, options));
  }
}
